import { lazy } from "react";

// project imports
import MainLayout from "../layout/MainLayout";
import Loadable from "./../components/Loadable";

// dashboard routing
const Statistics = Loadable(lazy(() => import("../views/statistics")));
const Customers = Loadable(lazy(() => import("../views/customers")));
const CustomerView = Loadable(lazy(() => import("../views/customers/view")));
const CustomersManage = Loadable(
  lazy(() => import("../views/customers/manage"))
);
const CustomerManageChildAccountIndex = Loadable(
  lazy(() =>
    import("../views/customers/manage/CustomerManageChildAccountIndex")
  )
);
const Subscriptions = Loadable(lazy(() => import("../views/subscriptions")));
const CallCustomerHistory = Loadable(
  lazy(() => import("../views/call-customer-history"))
);
const ServicesStatus = Loadable(lazy(() => import("../views/services-status")));
const Cabinets = Loadable(lazy(() => import("../views/cabinets")));
const Reports = Loadable(lazy(() => import("../views/reports")));
const Users = Loadable(lazy(() => import("../views/users")));
const Task = Loadable(lazy(() => import("../views/task")));
const MaintenanceTask = Loadable(
  lazy(() => import("../views/maintenance-task"))
);
const MaintenanceTaskDetails = Loadable(
  lazy(() => import("../views/maintenance-task/task-details"))
);
const SubscriptionRequestTask = Loadable(
  lazy(() => import("../views/subscription-request-task"))
);
// const MaintenanceTaskReplies = Loadable(
//   lazy(() => import("../views/task/task-replies/index"))
// );

// const ExpiryCustomers = Loadable(
//   lazy(() => import("../views/expiry-customers"))
// );
const TaskDetails = Loadable(lazy(() => import("../views/task/task-details")));
//roles & permissions
const RolesPermissions = Loadable(
  lazy(() => import("../views/roles-permissions"))
);
//clients
const Clients = Loadable(lazy(() => import("../views/clients")));
const Transactions = Loadable(lazy(() => import("../views/transactions")));
// const Tickets = Loadable(lazy(() => import("../views/tickets")));
const SubscriptionService = Loadable(
  lazy(() => import("../views/subscription-service"))
);
const Variables = Loadable(lazy(() => import("../views/variables")));
const Home = Loadable(lazy(() => import("../views/home")));

const Offers = Loadable(lazy(() => import("../views/offers")));
const Announcements = Loadable(lazy(() => import("../views/announcement")));
// const QA = Loadable(lazy(() => import("../views/qa")));
const UserWallet = Loadable(lazy(() => import("../views/user-wallet")));
const WalletTransaction = Loadable(
  lazy(() => import("../views/wallet-transaction"))
);

const FatMaintenance = Loadable(lazy(() => import("../views/fat-Maintenance")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/user-wallet",
      element: <UserWallet />,
    },
    {
      path: "/wallet-transaction",
      element: <WalletTransaction />,
    },
    {
      path: "/statistics",
      element: <Statistics />,
    },
    {
      path: "/fat-maintenance",
      element: <FatMaintenance />,
    },
    {
      path: "/announcements",
      children: [
        {
          path: "/announcements",
          element: <Announcements />,
          index: true,
        },
      ],
    },
    {
      path: "/variables",
      children: [
        {
          path: "/variables",
          element: <Variables />,
          index: true,
        },
      ],
    },
    // {
    //   path: "/qa",
    //   children: [
    //     {
    //       path: "/qa",
    //       element: <QA />,
    //       index: true,
    //     },
    //   ],
    // },
    {
      path: "/offers",
      element: <Offers />,
    },
    {
      path: "/services-status",
      children: [
        {
          path: "/services-status",
          element: <ServicesStatus />,
          index: true,
        },
      ],
    },
    {
      path: "/subscription-service",
      children: [
        {
          path: "/subscription-service",
          element: <SubscriptionService />,
          index: true,
        },
      ],
    },
    {
      path: "/transactions",
      children: [
        {
          path: "/transactions",
          element: <Transactions />,
          index: true,
        },
      ],
    },
    {
      path: "/clients",
      children: [
        {
          path: "/clients",
          element: <Clients />,
          index: true,
        },
      ],
    },
    {
      path: "/call-customer-history",
      children: [
        {
          path: "/call-customer-history",
          element: <CallCustomerHistory />,
          index: true,
        },
      ],
    },
    {
      path: "/task",
      children: [
        {
          path: "/task",
          element: <Task />,
          index: true,
        },
        {
          path: "/task/:id",
          element: <TaskDetails />,
        },
      ],
    },
    {
      path: "/maintenance-task",
      children: [
        {
          path: "/maintenance-task",
          element: <MaintenanceTask />,
          index: true,
        },
        {
          path: "/maintenance-task/:id",
          element: <MaintenanceTaskDetails />,
        },
      ],
    },
    {
      path: "/subscription-request-task",
      children: [
        {
          path: "/subscription-request-task",
          element: <SubscriptionRequestTask />,
          index: true,
        },
        {
          path: "/subscription-request-task/:id",
          element: <TaskDetails />,
        },
      ],
    },
    {
      path: "/customers",
      children: [
        {
          path: "/customers",
          element: <Customers />,
          index: true,
        },
        {
          path: "/customers/add",
          element: <CustomersManage />,
        },
        {
          path: "/customers/add/child/:parent_customer_id",
          element: <CustomerManageChildAccountIndex />,
        },
        {
          path: "/customers/add-with-task/:task_id",
          element: <CustomersManage />,
        },
        {
          path: "/customers/edit/:id",
          element: <CustomersManage />,
        },
        {
          path: "/customers/view/:id",
          element: <CustomerView />,
        },
      ],
    },
    {
      path: "/users",
      children: [
        {
          path: "/users",
          element: <Users />,
          index: true,
        },
      ],
    },
    // {
    //   path: "/tickets",
    //   children: [
    //     {
    //       path: "/tickets",
    //       element: <Tickets />,
    //       index: true,
    //     },
    //   ],
    // },
    {
      path: "/cabinets",
      children: [
        {
          path: "/cabinets",
          element: <Cabinets />,
          index: true,
        },
      ],
    },
    {
      path: "/subscriptions",
      children: [
        {
          path: "/subscriptions",
          element: <Subscriptions />,
          index: true,
        },
      ],
    },
    {
      path: "/reports",
      children: [
        {
          path: "/reports",
          element: <Reports />,
        },
      ],
    },
    {
      path: "/roles-permissions",
      children: [
        {
          index: true,
          element: <RolesPermissions />,
        },
      ],
    },
  ],
};

export default MainRoutes;
