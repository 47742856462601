import { useState, useEffect } from "react";
import {
  Box,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Badge,
} from "@mui/material";

//styles and theme
import { useTheme } from "@mui/material/styles";
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
import Can from "../../../helpers/Can";
//icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { AiOutlineHome } from "react-icons/ai";

import { useLocation, useNavigate } from "react-router-dom";
import { RiCustomerService2Fill } from "react-icons/ri";
import { TbReportSearch, TbServer2, TbSocial } from "react-icons/tb";
import { HiOutlineCreditCard } from "react-icons/hi";
import { FiUsers } from "react-icons/fi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { RiShieldUserLine } from "react-icons/ri";
import { BiTask } from "react-icons/bi";
import { GrHostMaintenance, GrTransaction } from "react-icons/gr";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { LiaBroadcastTowerSolid } from "react-icons/lia";
import { IoMdRefresh } from "react-icons/io";
import { VscVariableGroup } from "react-icons/vsc";
import { FaRegChartBar } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";
import { TfiAnnouncement } from "react-icons/tfi";
import { FaGlasses } from "react-icons/fa6";
import { LuUtilityPole, LuWallet } from "react-icons/lu";
import { TbTransform } from "react-icons/tb";

//store
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "../../../app/slices/appSlice";

const SideBar = () => {
  const {
    user: {
      info: { permissions },
    },
  } = useSelector((state) => state.profile);
  const {
    task_notifications: { new_customer, maintenance, subscription_request },
  } = useSelector((state) => state.task);

  //uses
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //constants
  const drawerWidth = 220;

  //states
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  // const [mobileOpen, setMobileOpen] = useState(false);

  //selectors and media queries
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const drawerOpen = useSelector((state) => state.app.is_drawer_opened);

  const links = [
    {
      id: 1,
      name: "الرئيسية",
      icon: <AiOutlineHome size={18} />,
      path: "/",
      sub: [],
      roles: [],
      can: "",
    },
    {
      id: 210,
      name: "الأحصائيات",
      icon: <FaRegChartBar size={18} />,
      path: "/statistics",
      sub: [],
      roles: ["Manager"],
      can: "statistic_view",
    },
    {
      id: 2,
      name: "حالة الخدمات",
      icon: <HiOutlineStatusOnline size={18} />,
      path: "/services-status",
      sub: [],
      roles: ["Manager"],
      can: "service_status",
    },
    {
      id: 3,
      name: "الصلاحيات",
      icon: <RiShieldUserLine size={18} />,
      path: "/roles-permissions",
      sub: [],
      can: "management",
    },
    {
      id: 4,
      name: "الموظفين",
      icon: <HiOutlineBuildingOffice size={18} />,
      path: "/users",
      sub: [],
      can: "user_manage",
    },
    {
      id: 5,
      name: "المشتركين",
      icon: <FiUsers size={18} />,
      path: "/customers",
      sub: [],
      can: "customer_view",
    },
    {
      id: 7,
      name: "الاشتراكات",
      icon: <HiOutlineCreditCard size={20} />,
      path: "/subscriptions",
      sub: [],
      can: "subscription_view",
    },
    {
      id: 120,
      name: "الوكلاء",
      icon: <LiaBroadcastTowerSolid size={18} />,
      path: "/clients",
      sub: [],
      can: "client_view",
    },
    {
      id: 133,
      name: "المعاملات الماليه",
      icon: <GrTransaction size={18} />,
      path: "/transactions",
      sub: [],
      can: "client_transaction_view",
    },
    {
      id: 6,
      name: "خدمة المشتركين",
      icon: <RiCustomerService2Fill size={18} />,
      path: "/call-customer-history",
      sub: [],
      can: "customer_call_history",
    },
    {
      id: 40,
      name: "عروض الاشتراكات",
      icon: <BiSolidOffer size={18} />,
      path: "/offers",
      sub: [],
      can: "offers",
    },
    // {
    //   id: 8,
    //   name: "سحب الاجهزة",
    //   icon: <BsRouter size={20} />,
    //   path: "/expiry-customers",
    //   sub: [],
    //   can: "customer_expired_view",
    // },
    {
      id: 9,
      name: "الكابينات و الاعمدة",
      icon: <TbServer2 size={18} />,
      path: "/cabinets",
      sub: [],
      can: "cabinet_view",
    },
    // {
    //   id: 10,
    //   name: "التذاكر",
    //   icon: <IoTicketOutline size={18} />,
    //   path: "/tickets",
    //   sub: [],
    //   can: "ftth_ticket",
    // },
    {
      id: 11,
      name: "طلبات النصب",
      icon: <BiTask size={18} />,
      path: "/task",
      sub: [],
      is_badge: true,
      can: "task_new_customers_view",
    },
    {
      id: 20,
      name: "طلبات الاشتراك ",
      icon: <IoMdRefresh size={18} />,
      path: "/subscription-request-task",
      sub: [],
      is_badge: true,
      can: "task_subscription_request",
    },
    {
      id: 12,
      name: "مهام الصيانة",
      icon: <GrHostMaintenance size={18} />,
      path: "/maintenance-task",
      sub: [],
      is_badge: true,
      can: "task_maintenance_view",
    },
    {
      id: 11209,
      name: "صيانة الاعمدة",
      icon: <LuUtilityPole size={18} />,
      path: "/fat-maintenance",
      sub: [],
      is_badge: false,
      can: "is_maintenance_support",
    },
    {
      id: 1226,
      name: "محافظ الموظفين",
      icon: <LuWallet size={18} />,
      path: "/user-wallet",
      sub: [],
      can: "wallet_view",
    },
    {
      id: 1221,
      name: "حركة المحافظ",
      icon: <TbTransform size={18} />,
      path: "/wallet-transaction",
      sub: [],
      can: "wallet_transactions",
    },
    // {
    //   id: 11200,
    //   name: "تقييم اداء الصيانة",
    //   icon: <FaGlasses size={18} />,
    //   path: "/qa",
    //   sub: [],
    //   can: "is_quality",
    // },
    {
      id: 14,
      name: "خدمات الوكلاء",
      icon: <TbSocial size={18} />,
      path: "/subscription-service",
      sub: [],
      can: "client_subscription_service_view",
    },
    {
      id: 13,
      name: "التقارير",
      icon: <TbReportSearch size={18} />,
      path: "/reports",
      sub: [],
      can: "report_view",
    },
    {
      id: 30,
      name: "المتغيرات",
      icon: <VscVariableGroup size={18} />,
      path: "/variables",
      sub: [],
      can: "update_variables",
    },
    {
      id: 3789,
      name: "الأعلانات",
      icon: <TfiAnnouncement size={18} />,
      path: "/announcements",
      sub: [],
      can: "announcements",
    },
  ];

  const getNotificationCount = (id) => {
    switch (id) {
      case 11:
        return new_customer;
      case 12:
        return maintenance;
      case 20:
        return subscription_request;
      default:
        return 0;
    }
  };

  const drawerLinks = (
    <Box m={1}>
      <List
        dense={false}
        sx={{
          "& .MuiListItem-root:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: "8px",
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: "8px",
            fontWeight: "bold",
          },
        }}
      >
        {links.map((item, index) => (
          <div key={index}>
            {(Can(item.can) || item.can === "") && (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  key={index}
                  selected={item.path === "/" + pathname.split("/")[1]}
                  onClick={() => {
                    if (item.sub.length == 0) navigate(item.path);
                    if (index === selectedID) {
                      setOpen(!open);
                    } else {
                      setOpen(true);
                      setSelectedID(index);
                    }
                  }}
                >
                  {/* notification badge for new customer install and maintenance */}
                  <Badge
                    badgeContent={getNotificationCount(item.id)}
                    invisible={item?.is_badge ? false : true}
                    color="primary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "25px",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                  </Badge>
                  <ListItemText
                    sx={{
                      fontSize: "0.8rem",
                      // padding: "0px 15px 0px 0px",
                    }}
                    disableTypography={true}
                    primary={item.name}
                  />
                  {item.sub.length > 0 ? (
                    open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : (
                    ""
                  )}
                </ListItemButton>
              </ListItem>
            )}
          </div>
        ))}
      </List>
    </Box>
  );
  const drawerViewAndScroll = (
    <>
      <Box>
        <Box
          sx={{ display: "flex", mx: "auto", justifyContent: "center", mt: 1 }}
        >
          <img
            src={require("../../../assets/images/ftth.jpeg")}
            height={55}
            alt="logo"
          />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={
            {
              // height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
              // paddingLeft: "10px",
              // paddingRight: "10px",
            }
          }
        >
          {drawerLinks}
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>{drawerLinks}</Box>
      </MobileView>
    </>
  );

  useEffect(() => {
    if (matchUpMd) {
      dispatch(setDrawer());
    }
  }, [matchUpMd]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: drawerOpen ? drawerWidth : "0px" }}
      aria-label="sidebar"
    >
      {permissions.length > 0 && (
        <Drawer
          dir="rtl"
          variant={matchUpMd ? "persistent" : "temporary"}
          anchor="left"
          open={drawerOpen}
          onClose={() => dispatch(setDrawer())}
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              borderRight: "none",
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {drawerViewAndScroll}
        </Drawer>
      )}
    </Box>
  );
};

export default SideBar;
