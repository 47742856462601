import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import dayjs from "dayjs";

const fatMaintenanceApi = factory.get("fat_maintenance");

const initialState = {
  fat_maintenance: {
    data: [],
    loading: false,
    total: 0,
    dialog: false,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      cabinet_id: null,
      pole_id: null,
    },
  },
};

const fatMaintenanceSlice = createSlice({
  name: "fatMaintenance",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.fat_maintenance.data = payload.data;
      state.fat_maintenance.total = payload.total;
      state.fat_maintenance.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.fat_maintenance.filters = {
        ...state.fat_maintenance.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.fat_maintenance.filters = initialState.fat_maintenance.filters;
    },
  },
});

export const { setData, setLoading, setFilters, resetFilters } =
  fatMaintenanceSlice.actions;

export default fatMaintenanceSlice.reducer;

//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().fatMaintenance.fat_maintenance.filters;
    const filters_params = {
      ...filters,
      cabinet_id: filters.cabinet_id?.id || null,
      pole_id: filters.pole_id?.id || null,
    };
    dispatch(setLoading("fat_maintenance"));
    const res = await fatMaintenanceApi.index(filters_params);
    dispatch(setLoading("fat_maintenance"));
    dispatch(setData(res.data));
  } catch (err) {
    dispatch(setLoading("fat_maintenance"));
    throw new Error(err);
  }
};

export const FatMaintenanceAction = {
  index,
};
