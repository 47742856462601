import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/qa-maintenance?${p}`);
  },
  byId(id) {
    return api.get(`/api/v1/qa-maintenance/${id}`);
  },
  create(data) {
    return api.post(`/api/v1/qa-maintenance`, data);
  },
  update(data) {
    return api.put(`/api/v1/qa-maintenance/${data.id}`, data);
  },
};
